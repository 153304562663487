import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { Grid, Typography, Link } from "@material-ui/core";
import { Helmet } from "react-helmet";

import { FzForBusinessQuery } from "../../graphql-types";
import {
  Header,
  HomeHero,
  Footer,
  ImageWithStand,
  MarkdownDescription,
  BenefitItem,
  QuotesSlider,
  AwardItem,
  useHomeStyles,
  useFzForBusinessStyles,
  ContactUsForm,
} from "../components";

const anchors = [
  "augmented-intelligence",
  "easier-to-excel",
  "personalised-development",
];

interface Props {
  data: FzForBusinessQuery;
}

const FzForBusiness: React.FC<Props> = ({ data }: Props) => {
  const { strapiFzForBusinessContent } = data;

  const homeClasses = useHomeStyles();
  const classes = useFzForBusinessStyles();

  return (
    <div>
      <Helmet title="Flourishzone for Business">
        <meta
          name="description"
          content={strapiFzForBusinessContent.subtitle}
        />
        <meta name="image" content="/fz-for-business-hero.png" />
        <meta property="og:url" content="/fz-for-business" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={strapiFzForBusinessContent.subtitle}
        />
        <meta property="og:image" content="/fz-for-business-hero.png" />
      </Helmet>

      <Header />
      <HomeHero
        img="/fz-for-business-hero.png"
        title={strapiFzForBusinessContent.title}
        subtitle={strapiFzForBusinessContent.subtitle}
        tab={2}
        actionLabel={strapiFzForBusinessContent.call_to_action}
      />
      <div className={classes.content}>
        {strapiFzForBusinessContent.images_with_stand.map((section, index) => (
          <Grid
            className={homeClasses.description_section}
            container
            justify="center"
            id={anchors[index]}
            key={section.id}
          >
            <Grid container item xs={11} md={10} spacing={3}>
              <Grid
                className={clsx(homeClasses.order, section.position ?? "left")}
                item
                xs={12}
                md={6}
              >
                <ImageWithStand
                  src={section.image[0].url}
                  standColor={section.color}
                  standPosition={section.position === "left" ? "right" : "left"}
                  //@ts-ignore
                  adornment={section.adornment}
                />
              </Grid>
              <Grid
                className={clsx(
                  homeClasses.order,
                  section.position === "left" ? "right" : "left"
                )}
                item
                xs={12}
                md={6}
              >
                <MarkdownDescription
                  accentColor={section.color}
                  description={section.description}
                />
                <Link
                  className={clsx(
                    homeClasses.description_item,
                    homeClasses.description_link
                  )}
                  href="https://portal.flourish.zone/register"
                >
                  Find out more
                </Link>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
          id="features-and-benefits"
        >
          <Grid item xs={11} md={10}>
            <div className={homeClasses.centered_title}>
              <Typography variant="h4">
                <b>{strapiFzForBusinessContent.benefits_section_title}</b>
              </Typography>
              <Typography variant="body1">
                {strapiFzForBusinessContent.benefits_section_subtitle}
              </Typography>
            </div>
            <Grid container spacing={3}>
              {strapiFzForBusinessContent.benefit_items.map(benefit => (
                <Grid key={benefit.id} item xs={12} md={3}>
                  <BenefitItem
                    title={benefit.title}
                    description={benefit.description}
                    // @ts-ignore
                    icon={benefit.icon}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
          id="what-clients-say"
        >
          <Grid item xs={11} md={10}>
            <div className={homeClasses.centered_title}>
              <Typography variant="h4">
                <b>{strapiFzForBusinessContent.reviews_section_title}</b>
              </Typography>
            </div>
            <Grid
              className={classes.reviews_section}
              justify="center"
              container
              spacing={3}
            >
              <Grid item xs={11} md={5}>
                <Typography variant="h4">
                  <b>{strapiFzForBusinessContent.reviews_section_subtitle}</b>
                </Typography>
              </Grid>
              <Grid item xs={11} md={7}>
                <QuotesSlider
                  slides={strapiFzForBusinessContent.review_items}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
          id="awards"
        >
          <Grid item xs={11} md={10} xl={8}>
            <div className={homeClasses.centered_title}>
              <Typography variant="h4">
                <b>{strapiFzForBusinessContent.awards_section_title}</b>
              </Typography>
            </div>
            <div className={classes.awards_container}>
              <img
                className="innovate_uk_logo"
                src="/innovate-uk.png"
                alt="innovate uk"
              />
              {strapiFzForBusinessContent.innovate_uk_award_items.map(award => (
                <AwardItem
                  key={award.id}
                  title={award.title}
                  description={award.description}
                  year={award.year}
                  color="purple"
                />
              ))}

              <img
                className="sbri_logo"
                src="/sbri-healthcare.png"
                alt="sbri healthcare"
              />
              {strapiFzForBusinessContent.sbri_award_items.map(award => (
                <AwardItem
                  key={award.id}
                  title={award.title}
                  description={award.description}
                  year={award.year}
                  color="cyan"
                />
              ))}
            </div>
          </Grid>
        </Grid>

        <Grid
          className={homeClasses.description_section}
          justify="center"
          container
          id="contact-us"
        >
          <Grid item xs={11} sm={10} md={6} lg={5} xl={4}>
            <ContactUsForm />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default FzForBusiness;

export const query = graphql`
  query FzForBusiness {
    strapiFzForBusinessContent {
      title
      subtitle
      call_to_action
      images_with_stand {
        id
        position
        color
        adornment
        description
        image {
          url
        }
      }
      benefits_section_title
      benefits_section_subtitle
      benefit_items {
        title
        description
        icon
        id
      }
      reviews_section_title
      reviews_section_subtitle
      review_items {
        id
        quote
        author
      }
      awards_section_title
      innovate_uk_award_items {
        id
        title
        description
        year
      }
      sbri_award_items {
        id
        description
        title
        year
      }
    }
  }
`;
